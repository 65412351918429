import {
  required,
} from "vuelidate/lib/validators";

import { NumberFormater } from "@/_helpers/funciones";

export default () => {
  return {
    form: {
      Bl: {
        YardDocumentId: {},
        VesselId: { required },
        VisitIdOrItineraryId: { required },
        BlCargoId: { required },
        YardOriginId: { required },
        TransactionDate: { required },
        Dua: { required },
        ReceiverName: { required },
        ReceiverCardId: { required },
        ReceptionBpAct: { required },
        Asycuda: { required },
      },
      GeneralData: {
        PortActivityName: { required },
        Weight: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
          required(Value) { return NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false },
        },
        Volumen: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
        },
        Quantity: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
          required(Value) { return NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false },
        },
      },
      DescriptionOfGoods:{
        DescriptionOfGoods: {},
      },
      Status: {
        YardDocumentStatusHistId: {},
        DocumentYardStatusId: { required },
        TransactionDate: { required },
        Observation: {},
      }
    },
  }
}